import { StaticQuery, graphql, Link } from "gatsby"
import React from "react"


export default function NuvoFooter({ customFooterCTA }) {
  const year = new Date().getFullYear();

    return (
        <StaticQuery
            query={graphql`
        query FooterQuery {
            footerJson {
                privacy {
                  text
                  url
              }
            }
          }
          
        `}
            render={data => 
              (
                <footer id="footer" className="footer">
                    <div className="container">
                      <div className="footer__copyright">
                        <p>© {year} Full Smile Foundation. All rights reserved.</p>

                        <p><a href="https://nuvolum.com/" target="_blank" title="Website Design and Marketing by Nuvolum">Website Design and Marketing</a> by Nuvolum.</p>
                      </div>
                    </div>
                </footer>
            )
          }
        />
    )
}
