import React, { Fragment, useEffect, useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import classnames from "classnames"

import Logo from "./Logo"
import Button from "../Buttons/Button"
import DropdownButton from "../Buttons/DropdownButton"

const NavBar = ({}) => {
  const data = useStaticQuery(graphql`
    query navQuery {
      allDataJson(filter: { optionsItem: { eq: "nav" } }) {
        nodes {
          logo
          specialButton {
            title
            url
            linkTitle
          }
          formButton {
            title
            url
            linkTitle
          }
        }
      }
    }
  `)
  const navData = data.allDataJson.nodes[0]

  return (
    <nav id="mainNav" className="navbar">
      <div className="container nav-flex">
        {/* <div className="is-hidden-touch"></div> */}

        <DropdownButton
          heading="Full Smile Day"
          links={[
            {
              link: {
                title: "https://secureform.seamlessdocs.com/f/m122s6zavdhw/",
                text: "English",
              },
            },
            {
              link: {
                title: "https://secureform.seamlessdocs.com/f/lp1boj635vjb",
                text: "Spanish",
              },
            },
          ]}
        />
        {/* <Button
          href={navData.formButton.url}
          buttonText={navData.formButton.title}
          external
          contained
          className="nav-form-button"
        /> */}

        <Link to="/" title="Return Home">
          <div className="nav-logo">
            <Logo logo={navData.logo} />
          </div>
        </Link>

        {/* <Button
          href={navData.specialButton.url}
          buttonText={navData.specialButton.title}
          tel
          contained
          className="is-hidden-mobile"
        /> */}
        <a
          style={{
            width: "fit-content",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
          href={navData.specialButton.url}
          className="standard-button contained is-hidden-mobile"
        >
          (806) 353-1055 EXT: 7256
          {/* <span
            dangerouslySetInnerHTML={{ __html: navData.specialButton.title }}
          /> */}
        </a>
      </div>
    </nav>
  )
}

export default NavBar
