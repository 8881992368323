import React from 'react'
import ImageMeta from '../ImageMeta'

const Logo = ({ logo }) => {
    return (
        <>
            <ImageMeta
                publicId={logo}
                cloudName="nuvolum"
                width="auto"
                responsive
                responsiveUseBreakpoints="true"
            />
        </>
    )
}

export default Logo
